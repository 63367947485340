/*------------------------------------------------------------------
  [Parsley]
*/
.parsley-errors-list.filled{
	margin-top: 10px;
	margin-bottom: 0;
	padding: 15px 20px;
	position: relative;
	background-color: @parsley-error-bg;
	color: @light-color;
	
	&:after{
		content: "";
		display: inline-block;
		position: absolute;
		top: -6px;
		left: 20px;
		.triangle(up, 7px, @parsley-error-bg);
	}

	> li{
		list-style: none;
	}
}