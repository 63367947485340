#p-editor-alert{

                        position: fixed;
                        right: 0;
                        left: 230px;
                        z-index: 1030;
                
}

.p-add-question{
    border: 2px dashed darken(@body-background, 16.8%);
    padding: 70px 60px 80px;
    background: transparent;
    text-align:center;
    color:darken(@body-background, 16.8%);
    .icon > span{
    	font-size:7em
    }


}
.p-icon-wrapper{
 
    margin-top: 123px;
    margin-left: auto;
    margin-right: auto;
    width: 400px;

	
}

.be-login{
    
    background: url('/assets/img/homepage.jpg');
    background-size: cover;
}

p{
    font-weight: 300;
}

.be-top-header .navbar-header .navbar-brand {
    background-image: url(/img/logo-white-xx.png);
    background-size: 80%;  
}


.hero-display-form {
    width: 320px;
    height: 240px;
    border: 3px solid #ffffff;
    margin-left:auto;
    margin-right:auto;
    margin-top:15px;
}
